import React, { useMemo } from "react"
import { connect } from "react-redux"
import { setPropertyFiltersField } from "../../actions/settingsActions"
import FilterCheckboxGroup from "./filterCheckboxGroup"

const AmenitiesOptions = ({ amenitiesFilter, amenities, ...props }) => {
  const amenitiesOptions = useMemo(
    () =>
      amenities?.data?.map?.(a => ({
        label: a.title || a.titleEn,
        value: a.id,
      })),
    [amenities]
  )

  const handleChange = val => props.setPropertyFiltersField("amenities", val)

  return (
    <FilterCheckboxGroup
      options={amenitiesOptions}
      handleChange={handleChange}
      value={amenitiesFilter}
    />
  )
}

const mapStateToProps = ({
  settings: { propertyFilters },
  data: { amenities },
}) => ({ amenities, amenitiesFilter: propertyFilters.amenities })

export default connect(mapStateToProps, { setPropertyFiltersField })(
  AmenitiesOptions
)
