import React, { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import AmenitiesOptions from "./amenitiesOptions"
import FilterCheckboxGroup from "./filterCheckboxGroup"
import PriceRange from "./priceRange"
import PropertyTypesOptions from "./propertyTypesOptions"
import FormInput from "../propertyForm/formInput"
import FormSelect, { CustomSelect } from "../propertyForm/formSelect"
import { offerTypeOptions } from "../propertyForm/steps/firstStep"
import { rentCheckOptions } from "../propertyForm/steps/fourthStep"
import { listedByOptions } from "../propertyForm/steps/secondStep"
import {
  amenitiesOptions,
  bathsCountOptions,
  bedRoomCountOptions,
  isConstructionCompletedOptions,
  isWithFurnitureOptions,
  propertyTypes,
} from "../propertyForm/steps/seventhStep"
import {
  clearPropertyFilters,
  openConfirmModal,
  setPropertyFiltersField,
} from "../../actions/settingsActions"
import { connect } from "react-redux"
import useForm from "../hooksComponents/useForm"
import Button from "../button"
import ChevRight from "../../assets/icons/chevRight"
import useMediaQuery from "../hooksComponents/useMediaQuery"
import { values } from "lodash"
import ReactSelect from "react-select"

const Label = ({ children, className = "" }) => (
  <label className={`font-bold px20 black mb-2 capitalized ${className}`}>
    {children}
  </label>
)
const cityOptions = [
  { title: "Homs", value: "Homs" },
  { title: "Deir ez-Zur", value: "Deir ez-Zur" },
  { title: "Ar Raqqah", value: "Ar Raqqah" },
  { title: "Hama", value: "Hama" },
  { title: "Damascus", value: "Damascus" },
  { title: "llion", value: "llion" },
  { title: "Tartus", value: "Tartus" },
  { title: "Al Bab", value: "Al Bab" },
  { title: "Duma", value: "Duma" },
  { title: "Manbij", value: "Manbij" },
  { title: "Dayr Hafir", value: "Dayr Hafir" },
  { title: "Ar Rastan", value: "Ar Rastan" },
  { title: "A'zaz", value: "A'zaz" },
  { title: "Al-Safirah", value: "Al-Safirah" },
  { title: "Darayya", value: "Darayya" },
  { title: "Al Mayadin", value: "Al Mayadin" },
  { title: "Salamiyah", value: "Salamiyah" },
  { title: "Al Tal", value: "Al Tal" },
  { title: "Al Bukamal", value: "Al Bukamal" },
  { title: "Al Tabqah", value: "Al Tabqah" },
  { title: "Jableh", value: "Jableh" },
  { title: "Badh Rajbana", value: "Badh Rajbana" },
  { title: "Khan Shaykhun", value: "Khan Shaykhun" },
  { title: "Al Hasakah", value: "Al Hasakah" },
  { title: "Tall Rifat", value: "Tall Rifat" },
  { title: "Tadif", value: "Tadif" },
  { title: "Nubl", value: "Nubl" },
  { title: "Al-Hawl", value: "Al-Hawl" },
]
const AdvancedFilters = ({
  propertyFilters,
  setPropertyFiltersField,
  type,
  handleBlur,
  setFieldValue,
  typeSearch = "filter",
}) => {
  const isMobile = useMediaQuery("(max-width: 528px)")
  const intl = useIntl()

  const handleAddressChange = unit => {
    console.log("unit", unit)
    setFieldValue("address", unit.value)
  }

  const setCityValue = city => {
    setPropertyFiltersField("city", city)
  }

  const { size } = propertyFilters || {}

  const {
    state: sizeRange,
    setState,
    onChange,
  } = useForm({
    min: "",
    max: "",
  })

  useEffect(() => {
    setState(size)
  }, [size])

  const setSizeRange = () => setPropertyFiltersField("size", sizeRange)

  // const PopupButton = ({ children, onClick }) => (
  //   <button
  //     type="button"
  //     onClick={onClick}
  //     className="br8 grey px20 d-flex align-items-center justify-content-between flex-1"
  //     style={{
  //       border: "1px solid #BFC7D2",
  //       width: "68%",
  //       height: "42px",
  //       color: "#2D3436",
  //       borderRadius: "8px",
  //     }}
  //   >
  //     {children}
  //     <ChevRight style={{ marginRight: "10px" }} />
  //   </button>
  // )

  return (
    <div className="advanced-filter mb-4">
      <div className="advanced-filter__col">
        {type === "advanced-filter" && (
          <div style={{ width: "70%" }}>
            <Label>
              <FormattedMessage defaultMessage="Search By Word" />
            </Label>
            <div className="signInInput" style={{ borderRadius: "8px" }}>
              <input
                style={{
                  height: "42px",
                  borderRadius: "8px",
                  fontSize: "15px",
                }}
                className="br4 greyLightBg"
                placeholder={intl.formatMessage({
                  defaultMessage: "Type a city name or a street name",
                })}
              />
            </div>
          </div>
        )}
        {/* {type === "advanced-filter" && (
          <div>
            <PopupButton onClick={null} children={null}>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "32px",
                }}
              >
                Select City
              </div>
            </PopupButton>
          </div>
        )} */}
        <div>
          <Label>
            <FormattedMessage defaultMessage="City" />
          </Label>
          {cityOptions ? (
            <ReactSelect
              name="city"
              placeholder={intl.formatMessage({
                defaultMessage: "Select City",
              })}
              getOptionLabel={options => options["title"]}
              getOptionValue={options => options["value"]}
              options={[...cityOptions]}
              onChange={city => setCityValue(city.value)}
              value={
                propertyFilters?.city
                  ? {
                      title: propertyFilters?.city,
                      value: propertyFilters?.city,
                    }
                  : null
              }
              className=""
              styles={{
                control: (base, props) => ({
                  ...base,
                  minHeight: "3rem",
                  height: "42px",
                  width: "70%",
                  borderRadius: "8px",
                  fontSize: "15px",
                  border: "1px solid #C4C4C4",
                  boxShadow: "none",
                }),
                option: (base, props) => ({
                  ...base,
                  backgroundColor: props.isSelected
                    ? "#3BBA9C"
                    : base.backgroundColor,
                }),
              }}
            />
          ) : null}
        </div>
        <div>
          <Label className="ms-2">
            <FormattedMessage defaultMessage="Property" />
          </Label>
          <FilterCheckboxGroup
            options={offerTypeOptions}
            value={propertyFilters.offerType}
            handleChange={val => setPropertyFiltersField("offerType", val)}
            type="rent-paid"
          />

          <div className="mt-5">
            <PriceRange
              customSizeClass="customSizeFilters"
              hasBackground={type === "advanced-filter" ? false : true}
              largerNums={type === "advanced-filter" ? false : true}
            />
          </div>
        </div>
        <div>
          <Label>
            <FormattedMessage defaultMessage="Size" />
          </Label>
          <div className="w-100 d-flex align-items-center space-x-20">
            <FormInput
              style={{ height: "42px", width: isMobile ? "120px" : "151px" }}
              label={intl.formatMessage({ defaultMessage: "Min Size" })}
              type="number"
              min="0"
              value={sizeRange?.min}
              name="min"
              onChange={onChange}
            />

            <FormInput
              style={{ height: "42px", width: isMobile ? "120px" : "151px" }}
              label={intl.formatMessage({ defaultMessage: "Max Size" })}
              type="number"
              min="0"
              value={sizeRange?.max}
              name="max"
              onChange={onChange}
            />

            {type === "filter" ? (
              <Button
                style={{
                  width: "42px",
                  height: "42px",
                  margin: "0px",
                  marginLeft: "9px",
                }}
                className="mt-4"
                disabled={
                  sizeRange?.min === propertyFilters?.size?.min &&
                  sizeRange?.max === propertyFilters?.size?.max
                }
                onClick={setSizeRange}
                color="secondary"
              >
                <FormattedMessage defaultMessage="Go" />
              </Button>
            ) : null}
          </div>
        </div>
      </div>

      <div className="advanced-filter__col">
        <div>
          <Label className="ms-2">
            <FormattedMessage defaultMessage="Listed by" />
          </Label>
          <FilterCheckboxGroup
            options={listedByOptions}
            value={propertyFilters.relationship}
            handleChange={val => setPropertyFiltersField("relationship", val)}
            type="rent-paid"
          />
        </div>

        <div>
          <Label className="ms-2">
            <FormattedMessage defaultMessage="Furniture" />
          </Label>
          <FilterCheckboxGroup
            options={isWithFurnitureOptions}
            value={propertyFilters.isWithFurniture}
            handleChange={val =>
              setPropertyFiltersField("isWithFurniture", val)
            }
            type="rent-paid"
          />
        </div>

        <div>
          <Label className="ms-2">
            <FormattedMessage defaultMessage="Type" />
          </Label>
          <PropertyTypesOptions />
          <FilterCheckboxGroup
            options={propertyTypes}
            value={propertyFilters.propertyTypes}
            handleChange={val => setPropertyFiltersField("propertyTypes", val)}
            type="type"
          />
        </div>

        <div>
          <Label className="ms-2">
            <FormattedMessage defaultMessage="Amenities" />
          </Label>
          <AmenitiesOptions />
          <FilterCheckboxGroup
            options={amenitiesOptions}
            value={propertyFilters.amenitiesOptions}
            handleChange={val =>
              setPropertyFiltersField("amenitiesOptions", val)
            }
            type="type"
          />
        </div>
      </div>

      <div className="advanced-filter__col">
        <div>
          <Label className="ms-2">
            <FormattedMessage defaultMessage="Rent paid" />
          </Label>
          <FilterCheckboxGroup
            options={rentCheckOptions}
            value={propertyFilters.rentCheck}
            handleChange={val => setPropertyFiltersField("rentCheck", val)}
            type="rent-paid"
          />
        </div>

        <div>
          <Label className="ms-2">
            <FormattedMessage defaultMessage="Construction" />
          </Label>
          <FilterCheckboxGroup
            options={isConstructionCompletedOptions}
            value={propertyFilters.isConstructionCompleted}
            handleChange={val =>
              setPropertyFiltersField("isConstructionCompleted", val)
            }
            type="rent-paid"
          />
        </div>

        <div>
          <Label className="ms-2">
            <FormattedMessage defaultMessage="Bed rooms" />
          </Label>
          <FilterCheckboxGroup
            options={bedRoomCountOptions}
            value={propertyFilters.bedRoomsCount}
            handleChange={val => setPropertyFiltersField("bedRoomsCount", val)}
            type="bed-rooms"
          />
        </div>

        <div>
          <Label className="ms-2">
            <FormattedMessage defaultMessage="Baths" />
          </Label>
          <FilterCheckboxGroup
            options={bathsCountOptions}
            value={propertyFilters.bathsCount}
            handleChange={val => setPropertyFiltersField("bathsCount", val)}
            type="bed-rooms"
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ settings: { lang, propertyFilters } }) => ({
  propertyFilters,
  lang,
})

export default connect(mapStateToProps, {
  setPropertyFiltersField,
  clearPropertyFilters,
  openConfirmModal,
})(AdvancedFilters)
