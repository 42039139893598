import React, { useMemo } from "react"
import { connect } from "react-redux"
import { setPropertyFiltersField } from "../../actions/settingsActions"
import FilterCheckboxGroup from "./filterCheckboxGroup"

const PropertyTypesOptions = ({ typeFilter, propertyTypes, ...props }) => {
  const propertyTypesOptions = useMemo(
    () =>
      propertyTypes?.data?.map?.(p => ({
        label: p.title || p.titleEn,
        value: p.id,
      })),
    [propertyTypes]
  )

  const handleChange = val => props.setPropertyFiltersField("type", val)

  return (
    <FilterCheckboxGroup
      options={propertyTypesOptions}
      handleChange={handleChange}
      value={typeFilter}
    />
  )
}

const mapStateToProps = ({
  settings: { propertyFilters },
  data: { propertyTypes },
}) => ({ propertyTypes, typeFilter: propertyFilters.type })

export default connect(mapStateToProps, { setPropertyFiltersField })(
  PropertyTypesOptions
)
