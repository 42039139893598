import React from "react"
import { FormattedMessage } from "react-intl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/button"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import {
  clearPropertyFilters,
  openConfirmModal,
} from "../actions/settingsActions"
import { connect } from "react-redux"
import { navigate } from "gatsby-link"
import AdvancedFilters from "../components/filter/AdvancedFilters"

const PropertiesFilter = ({ clearPropertyFilters, openConfirmModal }) => {
  const goToPropertiesPage = () => navigate("/properties")

  const handleCancelSearch = () => {
    openConfirmModal({
      title: <FormattedMessage defaultMessage="Cancel Search" />,
      msg: (
        <FormattedMessage defaultMessage="Are you sure you want to cancel the search?" />
      ),
      onConfirm: () => {
        clearPropertyFilters()
        navigate(-1)
      },
    })
  }
  return (
    <Layout>
      <Seo title="Properties Filters" />
      <div className="m-3 m-lg-5">
        <h1 className="px-36 mb-5 black font-600 text-decorated">
          <FormattedMessage defaultMessage="Simsar Advanced Search" />
        </h1>
        <AdvancedFilters type="advanced-filter" />
        <div className="w-100 pb-3 d-flex justify-content-between justify-content-md-end space-x-20 align-items-center">
          <Button
            color="white"
            onClick={handleCancelSearch}
            style={{ width: "192px", height: "58px" }}
          >
            <AiOutlineArrowLeft className="me-3 localized" />
            <FormattedMessage defaultMessage="Cancel" />
          </Button>

          <Button
            color="secondary"
            onClick={goToPropertiesPage}
            style={{
              width: "217px",
              paddingRight: "0px",
              paddingLeft: "0px",
              height: "58px",
            }}
          >
            <FormattedMessage defaultMessage="Search Properties" />

            <AiOutlineArrowRight className="ms-3 localized" />
          </Button>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({ settings: { lang } }) => ({
  lang,
})

export default connect(mapStateToProps, {
  clearPropertyFilters,
  openConfirmModal,
})(PropertiesFilter)
