import React from "react"
import CheckboxGroup from "../propertyForm/checkboxGroup"

const FilterCheckboxGroup = ({
  options = [],
  value,
  handleChange,
  type = null,
}) => {
  const handleSelect = (optionValue, checked) => {
    const newValue = Array.isArray(value)
      ? [...value, optionValue]
      : optionValue

    const ifNotCheckedNewValue = Array.isArray(value)
      ? value?.filter?.(v => v !== optionValue)
      : null

    handleChange(checked ? newValue : ifNotCheckedNewValue)
  }

  const getChecked = Array.isArray(value)
    ? option => value?.includes?.(option.value)
    : undefined

  if (options.length <= 0) return null

  return (
    <CheckboxGroup
      className="w-100"
      options={options}
      value={value}
      handleChange={handleSelect}
      getChecked={getChecked}
      type={type}
    />
  )
}

export default FilterCheckboxGroup
